import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS, ESTILO_INPUT_GENERICO } from "../../utils/const/estilos-const";
import CouponCard from "../../components/coupon-card";
import Select from "react-select";
import "react-widgets/dist/css/react-widgets.css";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import momentLocalizer from "react-widgets-moment";
import moment from "moment";
import { getListShortNames } from "../../api/api-short-names";
import { getListCurrencies } from "../../api/api-currency";
import { Info, } from "feather-icons-react/build/IconComponents";
import { getListConcept } from "../../api/api-concept";
import { INT_TRUE, INT_TYPE_DISCOUNT_MONEY, INT_TYPE_DISCOUNT_PERCENTAGE } from "../../utils/const/int-const";
import { createCoupon, editCoupon, editDraftCoupon, existeCoupon, getCountCoupons, saveDraftCoupon, saveImageCoupon } from "../../api/api-coupon";
import { PERCENTAGE } from "../../utils/const/string-const";
import SwitchComponent from "../../components/switch-component";
momentLocalizer(moment);

export default function Coupon() {
    const location = useLocation();
    const history = useHistory();
    const [title, setTitle] = useState("");
    const [row, setRow] = useState(null);
    const [selectedDiscount, setSelectedDiscount] = useState(PERCENTAGE);
    const [loadingScreen, setLoadingScreen] = useState(true);
    const [listCurrency, setListCurrency] = useState([]);
    const [currencySelected, setCurrencySelected] = useState(null);
    const [listAbbreviation, setListAbbreviation] = useState([]);
    const [abbreviationSelected, setAbbreviationSelected] = useState(null);
    const [sundayChecked, setSundayChecked] = useState(false);
    const [mondayChecked, setMondayChecked] = useState(false);
    const [tuesdayChecked, setTuesdayChecked] = useState(false);
    const [wednesdayChecked, setWebnesdayChecked] = useState(false);
    const [thursdayChecked, setThursdayChecked] = useState(false);
    const [fridayChecked, setFridayChecked] = useState(false);
    const [saturdayChecked, setSaturdayChecked] = useState(false);
    const [listApplyDiscountTo, setApplyDiscountTo] = useState([]);
    const [applyDiscountToSelected, setApplyDiscountToSelected] = useState(null);
    const [limitTotalUsesChecked, setLimitTotalUsesChecked] = useState(false);
    const [limitOnePerCustomerChecked, setLimitOnePerCustomerChecked] = useState(false);

    const [customCode, setCustomCode] = useState(false);
    const [valueCustomCode, setValueCustomCode] = useState("");
    const [timeBegin, setTimeBegin] = useState("00:00");
    const [timeEnd, setTimeEnd] = useState("23:59");

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 3;

    const [counter, setCounter] = useState(0);

    const [selectedImage, setSelectedImage] = useState(null);
    const [formatError, setFormatError] = useState(false);
    const [sizeError, setSizeError] = useState(false);

    // const [listBranches, setListBranches] = useState([]);
    // const [branchesSelected, setBranchesSelected] = useState([]);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            const data = location.state;
            console.log("data");
            console.log(data);
            setTitle(data.title);
            setRow(data.params);
            /*let objPermiso = {};
            objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CONFIGURACION;
            objPermiso.idMenuSegundoNivel = 0;
            objPermiso.idMenuTercerNivel = 0;
            let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
            // console.log('obtenerPermisos');
            // console.log(obtenerPermisos);
            let permiso = obtenerPermisos[0];
            setPermisos(permiso);
            // console.log('idTipoUsuario');
            // console.log(idTipoUsuario);
            // debugger;
            if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                setTieneAccesoAlModulo(true);
            }
            if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                if (permiso.accesoAlModulo === TRUE) {
                    setTieneAccesoAlModulo(true);
                }
            }*/
            const listAbbrev = await getListShortNames(true);
            setListAbbreviation(listAbbrev);
            const listCurre = await getListCurrencies(true);
            setListCurrency(listCurre);
            const listApplyDesc = await getListConcept({ group: 2 }, true);
            setApplyDiscountTo(listApplyDesc);
            const countCoupons = await getCountCoupons();
            setRow((prevRow) => ({ ...prevRow, numberCoupon: countCoupons }));

            // const branches = await getListBranches(true);
            // console.log('branches');
            // console.log(branches);
            // setListBranches(branches);
            if (data.params.id !== 0) {
                // console.log('modify coupon');
                const abbrev = listAbbrev.find(e => e.value === data.params.idAbbreviation);
                if (abbrev !== undefined) {
                    setAbbreviationSelected(abbrev);
                }
                const currency = listCurre.find(e => e.value === data.params.idCurrency);
                if (currency !== undefined) {
                    // console.log('currency');
                    // console.log(currency);
                    setCurrencySelected(currency);
                }
                if (data.params.idTypeDiscount === INT_TYPE_DISCOUNT_PERCENTAGE) {
                    setSelectedDiscount(PERCENTAGE);
                } else {
                    setSelectedDiscount(currency.label);
                }
                setRow((prevRow) => ({ ...prevRow, discountValue: data.params.valueDiscount }));
                // console.log('listApplyDesc');
                // console.log(listApplyDesc);
                const applyDiscount = listApplyDesc.find(e => e.value === data.params.idAppyDiscountTo);
                // console.log('applyDiscount');
                // console.log(applyDiscount);
                if (applyDiscount !== undefined) {
                    setApplyDiscountToSelected(applyDiscount);
                }
                if (data.params.sundayChecked === INT_TRUE) {
                    setSundayChecked(true);
                }
                if (data.params.mondayChecked === INT_TRUE) {
                    setMondayChecked(true);
                }
                if (data.params.tuesdayChecked === INT_TRUE) {
                    setTuesdayChecked(true);
                }
                if (data.params.wednesdayChecked === INT_TRUE) {
                    setWebnesdayChecked(true);
                }
                if (data.params.thursdayChecked === INT_TRUE) {
                    setThursdayChecked(true);
                }
                if (data.params.fridayChecked === INT_TRUE) {
                    setFridayChecked(true);
                }
                if (data.params.saturdayChecked === INT_TRUE) {
                    setSaturdayChecked(true);
                }
                if (data.params.limitUse === INT_TRUE) {
                    setLimitTotalUsesChecked(true);
                }
                setRow((prevRow) => ({ ...prevRow, limitUseValue: data.params.valueLimitUse }));
                if (data.params.limitOnePerUser === INT_TRUE) {
                    setLimitOnePerCustomerChecked(true);
                }
                if (data.params.customCode === INT_TRUE) {
                    setCustomCode(true);
                }
            }
            setLoadingScreen(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            // setLoadingScreen(false);
        }
    };

    const handleInputChange = (event) => {
        // console.log('handleInputChange');
        const { name, value } = event.target;
        // console.log(name);
        // console.log(value);
        setRow({ ...row, [name]: value });
    };

    const handleColorChange = (event) => {
        // setSelectedColor(event.target.value);
        setRow({ ...row, color: event.target.value });
    };

    const handleGoBackClick = () => {
        history.goBack();
    };

    const validateStepOne = () => {
        try {
            // console.log('validateStepOne');
            // console.log(row);
            let message = "";
            if (applyDiscountToSelected === null) {
                message = message + "Seleccione opcion Aplicar Descuento a \n";
            }
            if (abbreviationSelected === null) {
                message = message + "Seleccione Abreviatura \n";
            }
            if (row.numberCoupon === undefined || row.numberCoupon === null || String(row.numberCoupon).length === 0) {
                message = message + "Debe ingresar numero cupon \n";
            }
            if (customCode === false) {
                if (row.codeCoupon === undefined || row.codeCoupon === null || String(row.codeCoupon).length === 0) {
                    message = message + "Debe ingresar sufijo cupon \n";
                }
            }
            if (row.nameCoupon === undefined || row.nameCoupon === null || String(row.nameCoupon).length === 0) {
                message = message + "Debe ingresar nombre cupon \n";
            }
            if (row.description === undefined || row.description === null || String(row.description).length === 0) {
                message = message + "Debe ingresar descripcion del cupon \n";
            }
            if (String(message).length > 0) {
                return { error: true, message: message };
            }
            return { error: false };
        } catch (err) {
            return { error: true, message: err };
        }
    };

    const handleNextStep1 = async () => {
        try {
            // console.log('handleNextStep1');
            // console.log(row);
            const valid = validateStepOne();
            if (valid.error === true) {
                alert(valid.message);
                return;
            }
            if (customCode === false) {
                /**VERIFICAR SI EL CODIGO COUPON EXISTE */
                let objCodeCoupon = {};
                objCodeCoupon.id = row.id;
                objCodeCoupon.prefixCode = abbreviationSelected.label;
                objCodeCoupon.numberCoupon = row.numberCoupon;
                objCodeCoupon.codeCoupon = row.codeCoupon !== null && row.codeCoupon !== undefined ? row.codeCoupon : "";
                const couponExiste = await existeCoupon(objCodeCoupon);
                if (couponExiste === true) {
                    alert('Codigo Cupon ya existe');
                    return;
                }
            }
            /**saving image */;
            if (selectedImage) {
                await saveImage();
            } else {
                nextStep();
            }
        } catch (err) {
            console.log('handleNextStep1 err');
            console.log(err);
        }
    };

    const handlePreviousStep2 = () => {
        try {
            prevStep();
        } catch (err) {
            console.log('handlePreviousStep2 err');
            console.log(err);
        }
    };

    const validateStepTwo = () => {
        try {
            console.log('validateStepTwo');
            console.log(row);
            let message = "";
            if (row.discountValue === undefined || row.discountValue === null || String(row.discountValue).length === 0) {
                message = message + "Debe ingresar monto/porcentaje descuento \n";
            }
            if (String(message).length > 0) {
                return { error: true, message: message };
            }
            return { error: false };
        } catch (err) {
            return { error: true, message: err };
        }
    };

    const handleNextStep2 = () => {
        try {
            const valid = validateStepTwo();
            if (valid.error === true) {
                alert(valid.message);
                return;
            }
            nextStep();
        } catch (err) {
            console.log('handleNextStep2 err');
            console.log(err);
        }
    };

    const handlePreviousStep3 = () => {
        try {
            prevStep();
        } catch (err) {
            console.log('handlePreviousStep3 err');
            console.log(err);
        }
    };

    const validateStepTree = () => {
        try {
            // console.log('validateStepTree');
            // console.log(row);
            let message = "";
            if (row.dateBegin === undefined || row.dateBegin === null || String(row.dateBegin).length === 0) {
                message = message + "Debe ingresar Fecha Inicio \n";
            }
            if (moment(row.dateBegin).isValid() === false) {
                message = message + "Debe ingresar Fecha Inicio válida \n";
            }

            if (row.dateEnd === undefined || row.dateEnd === null || String(row.dateEnd).length === 0) {
                message = message + "Debe ingresar Fecha Fin \n";
            }
            if (moment(row.dateEnd).isValid() === false) {
                message = message + "Debe ingresar Fecha Fin válida \n";
            }

            if (limitTotalUsesChecked === true) {
                if (row.limitUseValue === undefined || row.limitUseValue === null || String(row.limitUseValue).length === 0) {
                    message = message + "Debe ingresar valor limite uso cupon \n";
                }
            }
            // if (branchesSelected === null || Array.isArray(branchesSelected) === false || branchesSelected.length === 0) {
            //     message = message + "Debe seleccionar sucursales donde se aplica el cupon \n";
            // }

            if (String(message).length > 0) {
                return { error: true, message: message };
            }
            return { error: false };
        } catch (err) {
            return { error: true, message: err };
        }
    };

    const handleNextStep3 = () => {
        try {
            const valid = validateStepTree();
            if (valid.error === true) {
                alert(valid.message);
                return;
            }
            saveCoupon();
        } catch (err) {
            console.log('handleNextStep3 err');
            console.log(err);
        }
    };

    const nextStep = () => {
        setCurrentStep((prevStep) => Math.min(prevStep + 1, totalSteps));
    };

    const prevStep = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
    };

    const getStepName = (stepNumber) => {
        let step = "";
        switch (stepNumber) {
            case 1:
                step = "General";
                break;
            case 2:
                step = "Finanzas";
                break;
            case 3:
                step = "Validez";
                break;

            default:
                step = "Paso " + stepNumber;
                break;
        }
        return step;
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        try {
            console.log('handleDrop');
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            uploadImage(file);
        } catch (err) {
            console.log('handleDrop err');
            console.log(err);
        }
    };

    const handleImageUpload = (e) => {
        try {
            console.log('handleImageUpload');
            const file = e.target.files[0];
            uploadImage(file);
        } catch (err) {
            console.log('handleImageUpload err');
            console.log(err);
        }
    };

    const uploadImage = (file) => {
        console.log('uploadImage');
        console.log(file);
        if (file) {
            // console.log('file size');
            // console.log(file.size);
            // console.log('1024-----');
            // console.log(1024 * 1024);
            if (file.size > 512 * 1024) {
                setSizeError(true);
            } else if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                setSizeError(false);
                setFormatError(false);
                setSelectedImage(URL.createObjectURL(file));
                setRow({ ...row, urlImage: null });
            } else {
                setFormatError(true);
            }
        }
    };

    const handleDeleteImage = () => {
        setSelectedImage(null);
        setFormatError(false);
        setSizeError(false);
    };

    const saveImage = async () => {
        try {
            // console.log('handleSaveImage');
            // console.log('selectedImage');
            // console.log(selectedImage);
            const imgElement = document.createElement("img");
            imgElement.src = selectedImage;
            imgElement.onload = async () => {
                const canvas = document.createElement("canvas");
                canvas.width = imgElement.width;
                canvas.height = imgElement.height;
                const context = canvas.getContext("2d");
                context.drawImage(imgElement, 0, 0);
                const base64Image = canvas.toDataURL("image/png");
                let fileType = base64Image.match(/^data:(.+);base64,/)[1];
                fileType = removePrefix(fileType, 'image/');

                let objEnviar = {};
                objEnviar.base64 = base64Image;
                objEnviar.fileName = moment().unix() + '.' + fileType;
                const guardado = await saveImageCoupon(objEnviar);
                if (guardado.error === false) {
                    setRow({ ...row, urlImage: guardado.value });
                    setSelectedImage(null);
                    nextStep();
                }
                if (guardado.error === true) {
                    alert('No se pudo guardar imagen, por favor revise que la resolucion no sea mayor a 566x360');
                    return;
                }
            };
        } catch (err) {
            console.log('handleSaveImage err');
            console.log(err);
        }
    };

    const removePrefix = (string, prefix) => {
        return string.replace(prefix, '');
    };

    const renderCouponForm = () => {
        return (
            <div className="p-6 max-w-2xl mx-auto bg-white shadow-lg rounded-lg">
                {/* Step Indicator */}
                <div className="flex justify-between mb-4">
                    {[...Array(totalSteps)].map((_, index) => (
                        <div key={index} className="flex-1 text-center">
                            <div
                                className={`w-10 h-10 rounded-full flex items-center justify-center mx-auto text-white ${currentStep === index + 1 ? '' : 'bg-gray-300'
                                    }`}
                                style={currentStep === index + 1 ? { backgroundColor: '#113A5F' } : {}}
                            >
                                {index + 1}
                            </div>
                            <div
                                className={`mt-2 text-sm font-medium ${currentStep === index + 1 ? 'text-[#113A5F]' : 'text-gray-500'
                                    }`}
                            >
                                {getStepName(index + 1)}
                            </div>
                        </div>
                    ))}
                </div>
                <hr className="mb-4" />

                <div className={`${currentStep === 1 ? 'block' : 'hidden'}`}>
                    {/* Apply Discount To*/}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">Aplicar descuento a:<span className="text-red-500">*</span></label>
                        <Select
                            options={listApplyDiscountTo}
                            isSearchable={true}
                            isClearable={true}
                            value={applyDiscountToSelected}
                            onChange={(event) => {
                                setApplyDiscountToSelected(event);
                            }}
                            placeholder="Seleccione"
                        />
                    </div>
                    {/* Abbreviaion */}
                    <div className="flex justify-center space-x-4 mb-4">
                        <div className="w-full">
                            <label className="block text-gray-700 font-bold mb-2">Seleccione Abreviatura<span className="text-red-500">*</span></label>
                            <Select
                                options={listAbbreviation}
                                isSearchable={true}
                                isClearable={true}
                                value={abbreviationSelected}
                                onChange={(event) => {
                                    setAbbreviationSelected(event);
                                }}
                                placeholder="Seleccione Abreviatura"
                            />
                        </div>
                    </div>
                    {/* Coupon Code and Name */}
                    <div className="grid grid-cols-2 gap-4 mb-4">

                        <div className={`${customCode === 1 || customCode === true ? 'block' : 'hidden'}`}>
                            <div className="flex justify-between">
                                <div>
                                    <label className="text-gray-700 font-bold">Codigo Cupon<span className="text-red-500">*</span></label>
                                </div>
                                <div>
                                    <SwitchComponent isOn={customCode} handleToggle={() => { setCustomCode(!customCode); }} />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-col items-center">
                                    <Info />
                                    <input
                                        type="text"
                                        name="valueCustomCode"
                                        value={row.valueCustomCode}
                                        onChange={handleInputChange}
                                        className={ESTILO_INPUT_GENERICO}
                                        placeholder={'Codigo'}
                                        maxLength={64}
                                    />
                                </div>
                                <div className="flex justify-end"><p className="text-xs text-gray-300">{String(row.valueCustomCode).length}</p><p className="text-xs text-gray-300">/64</p></div>
                            </div>
                        </div>

                        <div className={`${customCode === 0 || customCode === false ? 'block' : 'hidden'}`}>
                            <div className="flex justify-between">
                                <div>
                                    <label className="text-gray-700 font-bold">Codigo Cupon<span className="text-red-500">*</span></label>
                                </div>
                                <div>
                                    <SwitchComponent isOn={customCode} handleToggle={() => { setCustomCode(!customCode); }} />
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="flex flex-col items-center">
                                    <Info />
                                    <input
                                        type="text"
                                        name="codeCoupon"
                                        value={abbreviationSelected !== null ? abbreviationSelected.label : ""}
                                        className={ESTILO_INPUT_GENERICO}
                                        placeholder={abbreviationSelected?.label}
                                        readOnly
                                    />
                                </div>
                                <div className="flex flex-col items-center">
                                    <Info />
                                    <input
                                        type="number"
                                        name="numberCoupon"
                                        value={row.numberCoupon}
                                        onChange={handleInputChange}
                                        className={ESTILO_INPUT_GENERICO}
                                        placeholder={10}
                                        min={1}
                                    />
                                </div>
                                <div className="flex flex-col items-center">
                                    <Info />
                                    <input
                                        type="text"
                                        name="codeCoupon"
                                        value={row.codeCoupon}
                                        onChange={handleInputChange}
                                        className={ESTILO_INPUT_GENERICO}
                                        placeholder={row.couponCodePlaceHolder}
                                        maxLength={10}
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <label className="text-gray-700 font-bold">Nombre Cupon<span className="text-red-500">*</span></label>
                            <div className="flex flex-col items-center">
                                <Info />
                                <input
                                    type="text"
                                    name="nameCoupon"
                                    value={row.nameCoupon}
                                    onChange={handleInputChange}
                                    className={ESTILO_INPUT_GENERICO}
                                    placeholder={row.couponNamePlaceHolder}
                                    maxLength={36}
                                />
                            </div>
                            <div className="flex justify-end"><p className="text-xs text-gray-300">{String(row.nameCoupon).length}</p><p className="text-xs text-gray-300">/36</p></div>
                        </div>
                    </div>
                    {/* Discount Description */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">Descripcion<span className="text-red-500">*</span></label>
                        <textarea
                            name="description"
                            value={row.description}
                            onChange={handleInputChange}
                            className={ESTILO_INPUT_GENERICO}
                            placeholder={"Descripcion"}
                            rows={2}
                            maxLength={512}
                        />
                        <div className="flex justify-end"><p className="text-xs text-gray-300">{String(row.description).length}</p><p className="text-xs text-gray-300">/512</p></div>
                    </div>
                    {/* Color */}
                    <div className="mb-4">
                        <div className="flex items-center space-x-2">
                            <label className="text-gray-700 font-bold">
                                Seleccione Color
                            </label>
                            <label>(press ENTER to select)</label>
                            <input
                                type="color"
                                value={row.color}
                                onChange={handleColorChange}
                                className="h-10 border rounded-md cursor-pointer"
                            />
                        </div>
                    </div>
                    {/* imagen */}
                    <div className="mt-4">
                        <label htmlFor="imageUpload" className="block text-lg font-medium text-gray-700 mb-2">
                            Subir imagen:
                        </label>
                        <div
                            id="dropZone"
                            className={`w-70vw h-30vh border-2 border-gray-300 border-dashed rounded-md flex items-center justify-center ${selectedImage ? 'hidden' : ''
                                }`}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <input
                                id="imageUpload"
                                type="file"
                                className="hidden"
                                onChange={handleImageUpload}
                                accept=".jpg, .png"
                            />
                            <div className="text-center">
                                <p className="text-sm text-gray-500">Arrastra y suelta la imagen aquí o</p>
                                <button
                                    type="button"
                                    className="mt-2 py-2 px-4 border border-gray-300 rounded-md bg-red-300 text-gray-700 font-medium hover:bg-red-400"
                                    onClick={() => document.getElementById('imageUpload').click()}
                                >
                                    Subir imágenes
                                </button>
                            </div>
                        </div>
                        <label>Subir imagenes de tipo .png o .jpg<label className="text-xs"> (las imagenes deben ser menor a 1 MB)</label></label>
                        {formatError && (
                            <p className="text-red-500 text-sm mt-2">Formato de archivo no válido. Por favor, selecciona un archivo .jpg o .png.</p>
                        )}
                        {sizeError && (
                            <p className="text-red-500 text-sm mt-2">El tamaño del archivo excede el límite de 512 KB.</p>
                        )}
                    </div>

                    {selectedImage && (
                        <div className="mt-4">
                            <img src={selectedImage} alt="Preview" className="w-full rounded-md" />
                            <div className="flex justify-between mt-2">
                                <button
                                    type="button"
                                    className="py-1 px-2 bg-red-300 text-white font-medium rounded hover:bg-red-400"
                                    onClick={handleDeleteImage}
                                >
                                    Eliminar imagen
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="flex justify-end mt-2 mb-4">
                        <button
                            type="button"
                            className={ESTILO_BOTON_LISTAR_ATRAS}
                            onClick={handleNextStep1}
                        >
                            SIGUIENTE
                        </button>
                    </div>
                </div>

                <div className={`${currentStep === 2 ? 'block' : 'hidden'}`}>
                    {/* Currency */}
                    <div className="flex justify-center space-x-4 mb-4">
                        <div className="w-full">
                            <label className="block text-gray-700 font-bold mb-2">Seleccione Moneda<span className="text-red-500">*</span></label>
                            <Select
                                options={listCurrency}
                                isSearchable={true}
                                isClearable={true}
                                value={currencySelected}
                                onChange={(event) => {
                                    setCurrencySelected(event);
                                    if (event === null) {
                                        setSelectedDiscount(PERCENTAGE);
                                    } else {
                                        setSelectedDiscount(event.label);
                                    }
                                }}
                                placeholder="Seleccione Moneda"
                            />
                        </div>
                    </div>
                    {/* Discount Type Buttons */}
                    <div className="flex justify-center space-x-4 mb-4">
                        <button
                            className={`w-1/2 p-4 text-center rounded-md ${selectedDiscount !== PERCENTAGE
                                ? `text-white`
                                : "bg-gray-100 text-gray-700"
                                }`}
                            style={selectedDiscount !== PERCENTAGE ? { backgroundColor: row.color } : {}}
                            onClick={() => setSelectedDiscount(currencySelected?.label)}
                        >
                            <span className="text-2xl">{currencySelected?.label}</span> Descuento
                        </button>
                        <button
                            className={`w-1/2 p-4 text-center rounded-md ${selectedDiscount === PERCENTAGE
                                ? "text-white"
                                : "bg-gray-100 text-gray-700"
                                }`}
                            style={selectedDiscount === PERCENTAGE ? { backgroundColor: row.color } : {}}
                            onClick={() => setSelectedDiscount(PERCENTAGE)}
                        >
                            <span className="text-2xl">%</span> Descuento
                        </button>
                    </div>
                    {/* Discount Input */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">Monto/Porcentaje descuento en: {selectedDiscount === PERCENTAGE ? PERCENTAGE : currencySelected?.label}<span className="text-red-500">*</span></label>
                        <input
                            type="number"
                            name="discountValue"
                            value={row.discountValue}
                            onChange={handleInputChange}
                            className={ESTILO_INPUT_GENERICO}
                            placeholder={row.discountValuePlaceHolder}
                        />
                    </div>
                    <div className="flex justify-between mt-2 mb-4">
                        <button
                            type="button"
                            className={ESTILO_BOTON_GENERAL_CANCELAR}
                            onClick={handlePreviousStep2}
                        >
                            ANTERIOR
                        </button>
                        <button
                            type="button"
                            className={ESTILO_BOTON_LISTAR_ATRAS}
                            onClick={handleNextStep2}
                        >
                            SIGUIENTE
                        </button>
                    </div>
                </div>

                <div className={`${currentStep === 3 ? 'block' : 'hidden'}`}>
                    {/* Valid Between Dates */}
                    <label className="block text-gray-700 font-bold mb-2">Fecha validez</label>
                    <div className="flex items-center space-x-4 mb-4">
                        <div className="w-1/2">
                            <label>Fecha Inicio<span className="text-red-500 font-bold">*</span></label>
                            <DateTimePicker
                                format={"YYYY-MM-DD HH:mm"}
                                value={new Date(row.dateBegin)}
                                onChange={(date) => {
                                    const newDate = moment(date).format('YYYY/MM/DD HH:mm');
                                    setRow({ ...row, dateBegin: newDate, });
                                }}
                                time={true}
                                timeFormat={"HH:mm"}
                            />
                        </div>
                        <div className="w-1/2">
                            <label>Fecha Fin<span className="text-red-500 font-bold">*</span></label>
                            <DateTimePicker
                                format={"YYYY-MM-DD HH:mm"}
                                value={new Date(row.dateEnd)}
                                onChange={(date) => {
                                    const newDate = moment(date).format('YYYY/MM/DD HH:mm');
                                    setRow({ ...row, dateEnd: newDate, });
                                }}
                                time={true}
                                timeFormat={"HH:mm"}
                            />
                        </div>
                    </div>
                    {/* Days Valid */}
                    <label className="block text-gray-700 font-bold mb-2">Dias de validez<span className="text-red-500 font-bold">*</span></label>
                    <div className="flex flex-wrap space-x-4 items-center">
                        <label className="flex flex-col items-center mb-4">
                            <input type="checkbox" className="form-checkbox mb-1"
                                checked={sundayChecked}
                                onClick={() => { setSundayChecked(!sundayChecked) }}
                            />
                            <span>Domingo</span>
                        </label>

                        <label className="flex flex-col items-center mb-4">
                            <input type="checkbox" className="form-checkbox mb-1"
                                checked={mondayChecked}
                                onClick={() => { setMondayChecked(!mondayChecked) }}
                            />
                            <span>Lunes</span>
                        </label>

                        <label className="flex flex-col items-center mb-4">
                            <input type="checkbox" className="form-checkbox mb-1"
                                checked={tuesdayChecked}
                                onClick={() => { setTuesdayChecked(!tuesdayChecked) }}
                            />
                            <span>Martes</span>
                        </label>

                        <label className="flex flex-col items-center mb-4">
                            <input type="checkbox" className="form-checkbox mb-1"
                                checked={wednesdayChecked}
                                onClick={() => { setWebnesdayChecked(!wednesdayChecked) }}
                            />
                            <span>Miercoles</span>
                        </label>

                        <label className="flex flex-col items-center mb-4">
                            <input type="checkbox" className="form-checkbox mb-1"
                                checked={thursdayChecked}
                                onClick={() => { setThursdayChecked(!thursdayChecked) }}
                            />
                            <span>Jueves</span>
                        </label>

                        <label className="flex flex-col items-center mb-4">
                            <input type="checkbox" className="form-checkbox mb-1"
                                checked={fridayChecked}
                                onClick={() => { setFridayChecked(!fridayChecked) }}
                            />
                            <span>Viernes</span>
                        </label>

                        <label className="flex flex-col items-center mb-4">
                            <input type="checkbox" className="form-checkbox mb-1"
                                checked={saturdayChecked}
                                onClick={() => { setSaturdayChecked(!saturdayChecked) }}
                            />
                            <span>Sábado</span>
                        </label>
                    </div>
                    {/* Time Valid */}
                    <label className="block text-gray-700 font-bold mb-2">Horario validez</label>
                    <div className="flex items-center space-x-4 mb-4">
                        <div className="w-1/2">
                            <label>Hora Inicio<span className="text-red-500 font-bold">*</span></label>
                            <DateTimePicker
                                format={"HH:mm"}
                                value={row.timeBegin ? moment(row.timeBegin, "HH:mm").toDate() : new Date()}
                                onChange={(date) => {
                                    const newDate = moment(date).format('HH:mm');
                                    setRow({ ...row, timeBegin: newDate, });
                                }}
                                time={true}
                                timeFormat={"HH:mm"}
                                date={false}
                            />
                        </div>
                        <div className="w-1/2">
                            <label>Hora Fin<span className="text-red-500 font-bold">*</span></label>
                            <DateTimePicker
                                format={"HH:mm"}
                                value={row.timeEnd ? moment(row.timeEnd, "HH:mm").toDate() : new Date()}
                                onChange={(date) => {
                                    const newDate = moment(date).format('HH:mm');
                                    setRow({ ...row, timeEnd: newDate, });
                                }}
                                time={true}
                                timeFormat={"HH:mm"}
                                date={false}
                            />
                        </div>
                    </div>
                    {/* Limit Uses */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">Limite de Uso</label>
                        <div className="space-y-4">
                            <div className="flex items-center">
                                <input type="checkbox" className="mr-2" id="limitTotalUses"
                                    checked={limitTotalUsesChecked}
                                    onClick={() => { setLimitTotalUsesChecked(!limitTotalUsesChecked) }}
                                />
                                <label htmlFor="limitTotalUses" className="text-gray-700">
                                    Limitar el uso de este cupon
                                </label>
                            </div>
                            <input
                                type="number"
                                name="limitUseValue"
                                value={row.limitUseValue}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder={10}
                                min={1}
                            />
                            <div className="flex items-center">
                                <input type="checkbox" className="mr-2" id="limitPerCustomer"
                                    checked={limitOnePerCustomerChecked}
                                    onClick={() => { setLimitOnePerCustomerChecked(!limitOnePerCustomerChecked) }}
                                />
                                <label htmlFor="limitPerCustomer" className="text-gray-700">
                                    Limitar uso uno por usuario
                                </label>
                            </div>
                            {/* Discount Description */}
                            <div className="mb-4">
                                <label className="block text-gray-700 font-bold mb-2">Donde Aplica</label>
                                <textarea
                                    name="whereApplies"
                                    value={row.whereApplies}
                                    onChange={handleInputChange}
                                    className={ESTILO_INPUT_GENERICO}
                                    placeholder={"Donde Aplica"}
                                    rows={2}
                                    maxLength={2048}
                                />
                                <div className="flex justify-end"><p className="text-xs text-gray-300">{String(row.description).length}</p><p className="text-xs text-gray-300">/512</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between mt-2 mb-4">
                        <button
                            type="button"
                            className={ESTILO_BOTON_GENERAL_CANCELAR}
                            onClick={handlePreviousStep3}
                        >
                            ANTERIOR
                        </button>
                        {counter === 0 ?
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleNextStep3}
                            >
                                GUARDAR
                            </button>
                            :
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                            >
                                <svg
                                    className="animate-spin h-5 w-5 text-white mx-auto"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    />
                                </svg>
                            </button>
                        }
                    </div>
                </div>

            </div>
        );
    };

    const renderCouponView = () => {
        return (
            <CouponCard
                bgColor={row.color}
                discount={selectedDiscount}
                discountValue={row.discountValue}
                nameCoupon={row.nameCoupon}
                prefixCoupon={abbreviationSelected !== null ? abbreviationSelected.label : ""}
                numberCoupon={row.numberCoupon}
                codeCoupon={row.codeCoupon}
                dateBegin={row.dateBegin}
                dateEnd={row.dateEnd}
                description={row.description}
                sundayChecked={sundayChecked}
                mondayChecked={mondayChecked}
                tuesdayChecked={tuesdayChecked}
                wednesdayChecked={wednesdayChecked}
                thursdayChecked={thursdayChecked}
                fridayChecked={fridayChecked}
                saturdayChecked={saturdayChecked}
                selectedImage={selectedImage}
                urlImage={row.urlImage}
                whereApplies={row.whereApplies}
                customCode={customCode}
                valueCustomCode={row.valueCustomCode}
                timeBegin={row.timeBegin}
                timeEnd={row.timeEnd}
            />
        );
    };

    const saveCoupon = async () => {
        try {
            setCounter((prevCounter) => prevCounter + 1);
            let objSend = {};
            // console.log('row');
            // console.log(row);
            objSend.id = row.id;
            objSend.color = row.color;
            objSend.idAbbreviation = abbreviationSelected.value;
            objSend.idCurrency = currencySelected !== null ? currencySelected.value : null;
            let idTypeDiscount = 0;
            if (selectedDiscount === PERCENTAGE) {
                idTypeDiscount = INT_TYPE_DISCOUNT_PERCENTAGE;
            } else {
                idTypeDiscount = INT_TYPE_DISCOUNT_MONEY;
            }
            objSend.idTypeDiscount = idTypeDiscount;
            objSend.description = row.description;

            objSend.prefixCode = abbreviationSelected.label;
            objSend.numberCoupon = row.numberCoupon;
            objSend.codeCoupon = row.codeCoupon;
            objSend.nameCoupon = row.nameCoupon;
            objSend.customCode = customCode;
            objSend.valueCustomCode = row.valueCustomCode !== null ? row.valueCustomCode : "";
            objSend.valueDiscount = row.discountValue;
            objSend.idAppyDiscountTo = applyDiscountToSelected.value;

            objSend.dateBegin = row.dateBegin;
            objSend.dateEnd = row.dateEnd;
            objSend.timeBegin = row.timeBegin;
            objSend.timeEnd = row.timeEnd;
            objSend.sundayChecked = sundayChecked;
            objSend.mondayChecked = mondayChecked;
            objSend.tuesdayChecked = tuesdayChecked;

            objSend.wednesdayChecked = wednesdayChecked;
            objSend.thursdayChecked = thursdayChecked;
            objSend.fridayChecked = fridayChecked;
            objSend.saturdayChecked = saturdayChecked;
            objSend.limitUse = limitTotalUsesChecked;

            objSend.valueLimitUse = null;
            if (String(row.limitUseValue).length > 0) {
                objSend.valueLimitUse = row.limitUseValue;
            }
            objSend.limitOnePerUser = limitOnePerCustomerChecked;
            objSend.urlImage = row.urlImage;

            // objSend.validoParaSucursales = branchesSelected;
            // objSend.validoParaTodasSucursales = validForAllBranchesChecked;
            objSend.whereApplies = row.whereApplies;
            if (row.id === 0) {
                // console.log('crear correo');
                const created = await createCoupon(objSend);
                // console.log('created');
                // console.log(created);
                if (created === true) {
                    alert('Se creo correctamente');
                    handleGoBackClick();
                }
                if (created === false) {
                    alert('No se pudo crear');
                }
            }
            if (row.id !== 0) {
                // console.log('crear correo');
                const updated = await editCoupon(objSend);
                // console.log('created');
                // console.log(created);
                if (updated === true) {
                    alert('Se actualizo correctamente');
                    handleGoBackClick();
                }
                if (updated === false) {
                    alert('No se pudo actualizar');
                }
            }
            setCounter(0);
        } catch (err) {
            console.log('handleSaveClick err');
            console.log(err);
        }
    };

    const handleSaveDraftClick = async () => {
        try {
            setCounter((prevCounter) => prevCounter + 1);
            let objSend = {};
            // console.log('row');
            // console.log(row);
            objSend.id = row.id;
            objSend.color = row.color;
            objSend.idAbbreviation = abbreviationSelected !== null ? abbreviationSelected.value : null;
            objSend.idCurrency = currencySelected !== null ? currencySelected.value : null;
            let idTypeDiscount = 0;
            if (selectedDiscount === PERCENTAGE) {
                idTypeDiscount = INT_TYPE_DISCOUNT_PERCENTAGE;
            } else {
                idTypeDiscount = INT_TYPE_DISCOUNT_MONEY;
            }
            objSend.idTypeDiscount = idTypeDiscount;
            objSend.description = row.description;

            objSend.prefixCode = abbreviationSelected !== null ? abbreviationSelected.label : "";
            objSend.numberCoupon = row.numberCoupon;
            objSend.codeCoupon = row.codeCoupon;
            objSend.customCode = customCode;
            objSend.valueCustomCode = row.valueCustomCode !== null ? row.valueCustomCode : "";
            objSend.nameCoupon = row.nameCoupon;
            objSend.valueDiscount = row.discountValue;
            objSend.idAppyDiscountTo = applyDiscountToSelected !== null ? applyDiscountToSelected.value : null;

            objSend.dateBegin = row.dateBegin;
            objSend.dateEnd = row.dateEnd;
            objSend.timeBegin = row.timeBegin;
            objSend.timeEnd = row.timeEnd;
            objSend.sundayChecked = sundayChecked;
            objSend.mondayChecked = mondayChecked;
            objSend.tuesdayChecked = tuesdayChecked;

            objSend.wednesdayChecked = wednesdayChecked;
            objSend.thursdayChecked = thursdayChecked;
            objSend.fridayChecked = fridayChecked;
            objSend.saturdayChecked = saturdayChecked;
            objSend.limitUse = limitTotalUsesChecked;

            objSend.valueLimitUse = null;
            if (String(row.limitUseValue).length > 0) {
                objSend.valueLimitUse = row.limitUseValue;
            }
            objSend.limitOnePerUser = limitOnePerCustomerChecked;
            // objSend.validoParaTodasSucursales = validForAllBranchesChecked;
            objSend.whereApplies = row.whereApplies;

            if (row.id === 0) {
                // console.log('crear correo');
                const created = await saveDraftCoupon(objSend);
                // console.log('created');
                // console.log(created);
                if (created === true) {
                    alert('Se guardo borrador correctamente');
                    handleGoBackClick();
                }
                if (created === false) {
                    alert('No se pudo guardar borrador');
                }
            }
            if (row.id !== 0) {
                // console.log('crear correo');
                const updated = await editDraftCoupon(objSend);
                // console.log('created');
                // console.log(created);
                if (updated === true) {
                    alert('Se actualizo borrador correctamente');
                    handleGoBackClick();
                }
                if (updated === false) {
                    alert('No se pudo actualizar borrador');
                }
            }
            setCounter(0);
        } catch (err) {
            console.log('handleSaveDraftClick err');
            console.log(err);
        }
    }

    const render = () => {
        try {
            if (loadingScreen === true) {
                return (
                    <div>
                        Loading
                    </div>
                )
            }
            return (
                <div className="pt-4 px-4">
                    <div className="flex justify-between items-center">
                        <div>
                            <label className="pl-2.5 font-bold">
                                {title}
                            </label>
                        </div>
                        <div>
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleGoBackClick}
                            >
                                ATRAS
                            </button>
                        </div>
                    </div>
                    <hr className="mt-1" />
                    <div className="grid grid-cols-12 gap-4">

                        <div className="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6">
                            {renderCouponForm()}
                        </div>
                        <div className="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6">
                            {renderCouponView()}
                        </div>

                    </div>
                    <hr className="mt-1" />
                    <footer className="flex justify-between mt-2 mb-4">
                        <button
                            type="button"
                            className={ESTILO_BOTON_GENERAL_CANCELAR}
                            onClick={handleGoBackClick}
                        >
                            CANCELAR
                        </button>
                        {counter === 0 ?
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleSaveDraftClick}
                            >
                                GUARDAR BORRADOR
                            </button>
                            :
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                            >
                                <svg
                                    className="animate-spin h-5 w-5 text-white mx-auto"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    />
                                </svg>
                            </button>
                        }
                    </footer>
                </div>
            );
        } catch (err) {
            console.log("Coupons render");
            console.log(err);
            return (
                <div className=" w-1/2 m-auto px-[2rem] ">
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    };

    return render();
}
