import { useState } from "react";
import { useHistory } from "react-router-dom";
import { loginWeb } from "../../api/api-autenticacion";
import "./login.css";
// import logo from "./title.png";
import { sharedNextPage } from "../../utils/shared-functions/sharedFunctions";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { ACCESSTOKENCONNECTME } from "../../utils/const/http-const";

export default function LoginScreen() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [errorMsg, setErrorMsg] = useState("");
  const history = useHistory();

  const handleLogin = async () => {
    try {
      console.log('handleLogin');
      const data = {
        user: username, password
      };
      console.log(data);
      const resp = await loginWeb(data);
      // console.log('resp');
      // console.log(resp);
      if (resp.error === false) {
        localStorage.setItem(ACCESSTOKENCONNECTME, resp.body.token);
        sharedNextPage("/", null, history);
      }
    } catch (err) {
      console.log("loginOnClick err");
      console.log(err);
      alert("Something went wrong");
    }
  };

  const handleGoBack = () => {
    sharedNextPage("landing", null, history);
  }

  const handleRegisterRedirect = () => {
    try {
      console.log('handleRegisterRedirect');
      let obj = {};
      let data = {};
      data.id = 0;
      data.nit = "";
      data.nameCompany = "";
      data.phoneCompany = "";
      data.addressCompany = "";

      data.webSiteUrl = "";
      data.facebookUrl = "";
      data.tiktokUrl = "";

      data.nameContact = "";
      data.emailContact = "";
      data.user = "";
      data.password = "";
      data.confirmPassword = "";
      data.termsAgreement = false;
      obj.titulo = "SUSCRIBIR EMPRESA";
      obj.params = data;
      sharedNextPage("suscribirse", obj, history);
    } catch (err) {
      console.log('handleRegisterRedirect err');
      console.log(err);
    }
  }

  const render = () => {
    try {
      return (
        <>
          <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
              <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Login</h2>
              {/* <form onSubmit={handleLogin} className="space-y-6"> */}
              {/* Username Input */}
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Enter your username"
                  maxLength={36}
                />
              </div>
              <br />
              {/* Password Input */}
              <div className="relative">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="flex direction-row">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-1 w-full px-4 py-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Enter your password"
                    style={{
                      width: "90%"
                    }}
                    maxLength={24}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleLogin(); // Call your method here
                      }
                    }}
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                    className="absolute inset-y-0 flex items-center cursor-pointer text-gray-600"
                    style={{
                      width: "10%",
                      paddingTop: '12px',
                      position: 'relative'
                    }}
                  >
                    <EyeIcon
                      size={24}
                      style={{
                        position: "absolute",
                        transition: "opacity 0.3s ease-in-out",
                        opacity: showPassword ? 1 : 0,
                      }}
                    />
                    <EyeSlashIcon
                      size={24}
                      style={{
                        position: "absolute",
                        transition: "opacity 0.3s ease-in-out",
                        opacity: showPassword ? 0 : 1,
                      }}
                    />
                  </span>
                </div>
              </div>

              <br />
              {/* Login Button */}
              <div>
                <button
                  type="submit"
                  className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-indigo-700 transition-colors"
                  onClick={() => { handleLogin(); }}
                >
                  Login
                </button>
              </div>
              {/* </form> */}
              {/* Register Option */}
              <p className="text-center text-gray-600 mt-6">
                No tiene cuenta?{" "}
                <span
                  onClick={handleRegisterRedirect}
                  className="text-indigo-600 font-medium cursor-pointer hover:underline"
                >
                  Registrarse
                </span>
              </p>

              {/* Go Back Button */}
              <div className="mt-6">
                <button
                  onClick={handleGoBack}
                  className="w-full bg-gray-200 text-gray-700 py-2 px-4 rounded-md shadow-md hover:bg-gray-300 transition-colors"
                >
                  Atras
                </button>
              </div>
            </div>
          </div>
        </>
      );
    } catch (err) {
      // return <ErrorView />;
    }
  }

  return render();
}
